import React, { useContext,useState ,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty,toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import PromotionsIcon from "../assets/images/icon-promotion.png";
import iconReferral from "../assets/images/icon-referral.png";
import iconVip from "../assets/images/icon-vip.png";
import iconAffiliate from "../assets/images/icon-affiliate.png";
import iconTalk from "../assets/images/icon-talk.png";
import iconForum from "../assets/images/icon-forum.png";
import iconAmbassador from "../assets/images/icon-ambassador.png";
import HomeIcon from "../assets/images/icon-home.png";

const MenuSidebarNew = () => {

  let { logoutUser, setShowSidebar,lang ,user, setEn, setBr,launchEGTCasino,
    launchCasino,withoutLogin} = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener('scroll', () => {
      
      if(window.scrollY > 180){
       
        SetActiveClass('gamefixed');
      }else{
        SetActiveClass('');
      }
      
   });

  }, []);
  const {t} = useTranslation()
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <div>
        <div className="sidebar-wrapper">
          {/* <div className="top-sidebar mb-3 ps-1 d-flex justify-content-between align-items-center">
           
            <button
              className="bg-transparent border-0 p-0"
              onClick={() => setShowSidebar(false)}
            >
              <RxCross2 />
            </button>
          </div> */}
          <ul>
          <li><figure> <img src={HomeIcon} alt=""/></figure><Link to="/" className="text-decoration-none"> <span>{"Home"}</span></Link></li>

        {DepositWithrawData?.map((item,index) => {
          return (
            <li key={index}  onClick={() => {setFunc(item?.type); }}className={`${key == item?.type ? "active" : ""}`}>
              <figure><img src={item?.icons} alt=""/></figure>
              <a className="text-decoration-none"> <span>{lang == "bn" ? item?.titlebn : item?.title}</span></a>
          </li>
          );
        })}
        </ul>

       
        
      
        <ul>
        
            <li>
              <figure>
                <img
                  src={PromotionsIcon}
                  alt=""
               
                />
              </figure>
              <Link to="/promotions" className="text-decoration-none"> <span> Promotions </span></Link>
             
              </li>
              <li>
              <figure>
                <img
                  src={iconReferral}
                  alt=""
               
                />
              </figure>
              <Link to="/refer" className="text-decoration-none"> <span>  Referral Program  </span></Link>
             
              </li>
              <li>
              <figure>
                <img
                  src={iconVip}
                  alt=""
               
                />
              </figure>
              <Link to="/" className="text-decoration-none"> <span>  VIP  </span></Link>
             
              </li>
         
       
        </ul>
        <ul className="support-brand">
        
            <li> 
              <figure>
                <img
                  src={iconAffiliate}
                  alt=""
               
                />
              </figure>
              <Link to="/affilate" className="text-decoration-none">   Affiliate  </Link>
             
              </li>
              <li>
              <figure>
                <img
                  src={iconTalk}
                  alt=""
               
                />
              </figure>
              <Link to="/" className="text-decoration-none"> <p class=""> 24/7 LiveChat <span class="">Provides 24/7 Quality service</span></p></Link>
             
              </li>
              <li>
              <figure>
                <img
                  src={iconForum}
                  alt=""
               
                />
              </figure>
              <Link to="/" className="text-decoration-none"> Forum  </Link>
             
              </li>
              <li>
              <figure>
                <img
                  src={iconAmbassador}
                  alt=""
               
                />
              </figure>
              <Link to="/" className="text-decoration-none"> <p class=""> Brand Ambassador <span class="">Play with celebrity</span></p></Link>
             
              </li>
       
        </ul>
        <ul>
        
        {!isEmpty(user) ? (
          <>
          <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
             
              setBr();
              
            }}
          >
            <img src={FlagIconBn} alt="" />{" "}
            <p>বাংলা</p> 
          </div>
          <div
            className="language-select-div"
            onClick={() => {
              
                setEn();
              
            }}
          >
            <img src={ FlagIcon } alt="" />{" "}
            <p>English</p>
          </div>
        </li>
            
          </>):("")}</ul>
          {/* <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[0].firstLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon} <span>{lang == 'bn' ? item?.menubn : item?.menu}</span>{" "}
                    </Link>
                  </li>
                );
              })}
          </ul> */}
          {/* <button onClick={() => logoutUser()} className="logout-button">
            <BiLogOutCircle className="me-2" />
            {t("Logout")}
          </button>
          <div className="time-zone text-center pt-4">
            <AiOutlineGlobal className="me-2 fs-4" />{" "}
            <span>{t("Time_Zone")}：GMT+6:00</span>
          </div> */}
           
        </div>
        {
          key!="" &&
          <div class="menu-second">
          <ul class="menu-second-ul active">
          {data?.array?.map((res) => {
              return (
                  <li class=""   
                  onClick={() => {
                    if (!isEmpty(user)) {
                      if (res?.gameTypeCheck == "sports") {
                        window.open(res?.link, "_self");
                        } 
                     else if (data?.key == "sports") {
                      
                        
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                              isLobbyTrue:res?.isLobbyTrue
                            });
                          
                      } 
                      else if(res?.gameTypeCheck == "platForm") {
                        launchCasino({
                          platForm: res?.platForm,
                          gameType: res?.gameType,
                          casinoType: res?.casinoType,
                          isLobbyTrue:res?.isLobbyTrue
                        });
                      }
                     
                      else if(data?.key == "hotgame"){
                        launchCasino({
                          platForm: res?.platForm,
                          gameType: res?.gameType,
                          casinoType: res?.casinoType,
                        });
                      } else{
                        if (!isEmpty(user)) {
                          let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                          navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                          
                        } else {
                          navigate("/login");
                        }
                      }
                    } else {
                     navigate("/login");
                    }
                  }}>
                  <figure><img src={res?.image} alt=""/></figure><p>{res?.title}</p>
                  </li>
              );
            })}
        </ul>
      </div>
        }
       
      </div>
      
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
