import React from "react";
import { useState } from "react";
import SearchGames from "../../components/SearchGames";
import { Button } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
const SubCasino = ({ header,subCasino, setSubCasino,casinoFilter,casinoFilterHandel }) => {
  const [showGameSearchList, setShowGameSearchList] = useState(false);

  const live = ["SEXY", "EVO", "PT", "PP","HOTROAD"];
  const table = ["SPRIBE","KINGMAKER", "JILI","PG"];
  const slot = [
    "PP",
    "KINGMAKER",
    "DRAGOONSOFT",
    "PT",
    "JDB",
    "JILI",
    "SPADE"
  ];
  const fishing = ["JILI",, "JDB","YESBINGO","YL"];
  const egame = ["PP", "JDB"];
  const lottery = ["KINGMAKER", "JILI","YESBINGO"];
  const crash = ["SPRIBE","KINGMAKER", "JILI","PP"];
  const obj = {
    live: live,
    slot: slot,
    table: table,
    fishing: fishing,
    egame:egame,
    lottery:lottery,
    lottery:lottery,
    crash:crash
  };
  
  return (
    <>
      <div className="d-flex align-items-center subcasino" style={{marginTop:`52px`}}>
        {obj[header].map((res) => {
          return (
            <span
              onClick={() => setSubCasino(res)}
              className={subCasino === res && "active"}
            >
              {res}
            </span>
          );
        })}
        <Button
          className="bg-transparent border-0 text-dark"
          onClick={() => setShowGameSearchList(true)}
        >
          <AiOutlineSearch className="fs-3" />
        </Button>
      </div>
      
        {showGameSearchList && (
        <SearchGames setShowGameSearchList={setShowGameSearchList} />
      )}
    </>
  );
};

export default SubCasino;
