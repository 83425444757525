import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { isEmpty,toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import Jili from "../assets/imagesNew/jilii.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
const GameSlider = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const ref = useRef();
  const [data, setData] = useState({});
  const [getHotData, setHotData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const navigate = useNavigate();
  const {
    lang,
    user,
    launchEGTCasino,
    launchCasino,
    logoutUser,
    withoutLogin,
  } = useContext(AuthContext);
  const [key, setKey] = useState("sports");
  const [getHotKey, setHotKey] = useState("");

  useEffect(() => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == "sports";
        }
      })
    );

    window.addEventListener('scroll', () => {
      if(window.scrollY > 180){
       
        SetActiveClass('gamefixed');
      }else{
        SetActiveClass('');
      }
      
   });

  }, []);

  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const setHotKeyFun = (type) => {
    
    setHotData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == "hotgame";
        }
      })
    );
    setHotKey(type);
  };
  return (
    <div className="home-tabing-slider">
      <div className={"payment-sec "+ activeClass}  {...settings}>
        {DepositWithrawData?.map((item) => {
          return (
            <div
              onClick={() => {
                // if (isEmpty(user)) {
                //   withoutLogin();
                // } else {
                  if (!isEmpty(item?.link)) {
                    navigate(item?.link);
                  } else {
                    setFunc(item?.type);setHotKeyFun("")
                  }
                // }
              }}
              className={`payment-box ${key == item?.type ? "active" : ""}`}
            >
              <figure>
                <img
                  src={item?.icons}
                  alt=""
               
                />
              </figure>
              <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
            </div>
          );
        })}
      </div>
      <div className="main-slider-div">
        <div className="main-slider-inner-div">
          <div>
            <h5>{lang == "bn" ? data?.titlebn : data?.title}</h5>
          </div>
          {
            key=="hotgame" &&
            <div className="search-tab">
            <ul className="hot-listing">
              
              <li onClick={() => { setHotKeyFun("JILI");}} className={getHotKey=="JILI"?"active":""}>JILI</li>
              <li onClick={() => { setHotKeyFun("KINGMAKER");}} className={getHotKey=="KINGMAKER"?"active":""}>KINGMAKER</li>
              <li onClick={() => { setHotKeyFun("PG");}} className={getHotKey=="PG"?"active":""}>PG</li>
              
            </ul>
            </div>
          }
          <ul>
            {getHotKey=="" && data?.array?.map((res) => {
              return (
                <li>
                  <div
                    onClick={() => {
                      if (!isEmpty(user)) {
                        if (res?.gameTypeCheck == "sports") {
                          window.open(res?.link, "_self");
                          } 
                       else if (data?.key == "sports") {
                        
                          
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                isLobbyTrue:res?.isLobbyTrue
                              });
                            
                        } 
                        else if(res?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                            isLobbyTrue:res?.isLobbyTrue
                          });
                        }
                       
                        else if(data?.key == "hotgame"){
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                          });
                        } else{
                          if (!isEmpty(user)) {
                            let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                            navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                            
                          } else {
                            navigate("/login");
                          }
                        }
                      } else {
                       navigate("/login");
                      }
                    }}
                  >
                    <span>
                      <LazyLoadImage
                        effect="opacity"
                        alt={res?.img}
                        src={res?.image}
                      />
                    </span>
                    <p>{res?.title}</p>
                  </div>
                </li>
              );
            })}
               {getHotData?.array?.map((res) => {
              return (
                res.platForm==getHotKey&&(
                <li>
                  <div
                    onClick={() => {
                      if (!isEmpty(user)) {
                       
                          if (!isEmpty(user)) {
                           if (res?.gameTypeCheck == "sports") {
                            window.open(res?.link, "_self");
                            } else if (res?.gameTypeCheck == "platForm") {
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                              });
                            } else if (res?.gameType == "egtGame") {
                              launchEGTCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameTypeNew,
                                casinoType: res?.casinoType,
                              });
                            } else if (!isEmpty(res?.gameid)) {
                              launchEGTCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                gameid: res?.gameid,
                              });
                            } else {
                              if (
                                res?.platForm !== "" &&
                                res?.gameType !== "" &&
                                res?.casinoType !== ""
                              ) {
                                launchCasino({
                                  platForm: res?.platForm,
                                  gameType: res?.gameType,
                                  casinoType: res?.casinoType,
                                });
                              }
                            }
                          } else {
                            navigate("/login");
                          }
                        
                      } else {
                        withoutLogin()
                      }
                    }}
                  >
                    <span>
                      <LazyLoadImage
                        effect="opacity"
                        alt={res?.img}
                        src={res?.image}
                      />
                    </span>
                    <p>{res?.title}</p>
                  </div>
                </li>)
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GameSlider;
